import React from 'react';
import { graphql, Link } from 'gatsby';
import { INodePostSummary } from '../../interfaces/blog';
import { CustomCarousel } from '../Carousel/Carousel';
import { PostSlide } from './PostSlide/PostSlide';

type IProps = {posts: INodePostSummary[]} & { title: string };

export function PostList(props: IProps) {
  const { posts, title } = props;

  return (
    <>
      <span className="heading-short">Nasz blog</span>
      <div className="postlist__title-with-btn">
        <h2 className="heading postlist">{title}</h2>
        <Link to="/wszystkie-posty/" className="btn btn--secondary btn--link postlist__title-with-btn-link">Pokaż wszystkie</Link>
      </div>
      <div className="postlist__carousel-container">
        <CustomCarousel>
          {posts.map(({ node: post }) => <PostSlide key={post.id} node={post} />)}
        </CustomCarousel>
      </div>
    </>
  );
}

export const pageQuery = graphql`
    fragment PostListFields on wordpress__POST {
        id
        title
        featured_media {
            alt_text
            localFile {
                childImageSharp {
                    fluid(maxWidth: 352) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        tags {
            id
            name
        }
        author {
            name
            slug
            avatar_urls {
                wordpress_48
            }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
    }
`;
