/* eslint-disable import/no-default-export */
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
// eslint-disable-next-line import/extensions
import { INameSlug, INodePostSummary } from '../../../interfaces/blog';

export const PostSlide: React.FC<INodePostSummary> = ({ node: post }) => (
  <div
    key={post.id}
    className="post-slide"
  >
    <div className="post-slide__image-wrapper">
      {
        post && post.featured_media ? (
          <Img
            className="post-slide__image"
            alt={post.featured_media.alt_text}
            fluid={{ ...post.featured_media.localFile.childImageSharp.fluid, aspectRatio: 1 }}
          />
        ) : null
      }
    </div>
    <div className="post-slide__tags">
      {
        post.tags && post.tags.length ? (
          (post.tags as Array<INameSlug>).map(tag => (
            <Link key={tag.name} to={`/tags/${tag.name}/`} className="post-slide__tag-link"><span className="tag tag--blue tag--uppercase">{tag.name}</span></Link>))
        ) : null
      }
    </div>
    <Link className="post-slide__title" to={`/${post.slug}/`}>
      {post.title}
    </Link>
  </div>
);
