import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classnames from 'classnames';

const CustomArrow: React.FC<{ side: string; onClick?: any }> = ({ onClick, side }) => {
  const CustomArrowStyles = classnames('carousel__control-btn', {
    'carousel__control-btn--next': side === 'next',
    'carousel__control-btn--prev': side === 'prev',
  });

  return (
    <button onClick={onClick} className={CustomArrowStyles} aria-label="change slide" />
  );
};

export const CustomCarousel: React.FC = ({ children }) => (
  <div className="carousel">
    <Slider
      dots={false}
      infinite={false}
      slidesToShow={3}
      nextArrow={<CustomArrow side="next" />}
      prevArrow={<CustomArrow side="prev" />}
      responsive={[
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            // arrows: false, // turnOff arrows on mobile
          },
        },
      ]}
    >
      {children}
    </Slider>
  </div>
);
